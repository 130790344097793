import { Company } from "@/dto/company/company";
import { Credit } from "@/dto/company/credit";
import { useFetchStateStore } from "@/store/fetch-state";

interface CantCreateJobModal {
  active: boolean;
  title: string;
  description: string;
  action: () => void;
  buttonText: string;
}

const defaultModalState: CantCreateJobModal = {
  active: false,
  title: "Purchase Credits",
  description: "Please purchase at least one Job Credit before posting a job.",
  action: () => navigateTo(ROUTES.dashboardPayments),
  buttonText: "Purchase",
};

export const useCompanyStore = defineStore("company", () => {
  const supabase = useSupabaseClient();
  const company = ref<Company>();
  const fetchStateStore = useFetchStateStore();
  const cantCreateJobModal = ref<CantCreateJobModal>({ ...defaultModalState });

  const hasCredits = computed(() => company.value?.credits && (company.value?.credits as number) > 0);
  
  const toggleCantCreateJobModal = ({
    active,
    description,
    action,
    title,
    buttonText,
  }: Partial<CantCreateJobModal>) => {
    if (active) {
      cantCreateJobModal.value = {
        ...defaultModalState,
        active: true,
        description: description ?? defaultModalState.description,
        action: action ?? defaultModalState.action,
        title: title ?? defaultModalState.title,
        buttonText: buttonText ?? defaultModalState.buttonText,
      };
    } else {
      setTimeout(() => {
        cantCreateJobModal.value = { ...defaultModalState };
      }, 300);
    }
  };

  const getCompany = async (companyId: number) => {
    await fetchStateStore.fetchOnce("company", async () => {
      company.value = await $api<Company>(`${API_ROUTES.company}/${companyId}`);
    });
  };

  const updateCompany = async (companyData: any, logo?: File): Promise<Company> => {
    if (!company.value) throw createError({ message: ERROR_MESSAGES.company_not_found });
    if (logo) {
      const companyId = company.value.id.toString();
      const { error } = await supabase.storage.from(SUPABASE_BUCKETS.companyLogos).upload(companyId, logo, {
        upsert: true,
      });

      if (error) throw error;

      const { data } = supabase.storage.from(SUPABASE_BUCKETS.companyLogos).getPublicUrl(companyId);
      companyData.logo = data.publicUrl;
    }

    const comp = await $api<Company>(`${API_ROUTES.company}/${company.value.id}`, {
      method: "patch",
      body: companyData,
    });

    if (comp) company.value = comp;
    return company.value;
  };

  const deleteCompany = async () => {
    await $api<Company>(`${API_ROUTES.company}/${company.value?.id}`, {
      method: "delete",
    });
  };

  const claimFreeCredit = async () => {
    await $api<Credit>(`${API_ROUTES.company}/free-credit`, {
      method: "post",
    });
  };

  const assignCompanyDetails = (comp: Partial<Company>) => {
    if (!company.value) throw createError({ message: ERROR_MESSAGES.company_not_found });
    for (const [key, value] of Object.entries(comp)) {
      if (key in company.value) company.value[key as keyof Company] = value as never;
    }
  };

  const assignCredits = (credit: Credit) => {
    if (!company.value) throw createError({ message: ERROR_MESSAGES.company_not_found });

    const creditIndex = company.value?.credit_packages.findIndex((c) => c.id === credit.id);
    creditIndex !== -1
      ? (company.value.credit_packages[creditIndex] = credit)
      : company.value?.credit_packages.push(credit);
    company.value.credits = company.value.credit_packages.reduce(
      (total, item) => total + BigInt(item.remaining || 0),
      BigInt(0)
    );
  };

  return {
    company,
    getCompany,
    updateCompany,
    deleteCompany,
    assignCredits,
    assignCompanyDetails,
    claimFreeCredit,
    hasCredits,
    cantCreateJobModal,
    toggleCantCreateJobModal,
  };
});
