export const API_ROUTES = {
  authCallback: "/auth/callback",
  job: {
    index: "/api/job",
    report: "/api/job/report",
    repost: "/api/job/repost",
    archive: "/api/job/archive",
    matchingCandidates: "/api/job/matching-candidates",
  },
  employer: "/api/employer",
  company: "/api/company",
  application: {
    index: "/api/application",
    create: "/api/application/create",
    createMany: "/api/application/create-many",
  },
  candidate: {
    index: "/api/candidate",
    report: "/api/candidate/report",
  },
  notification: "/api/notification",
  chat: {
    index: "/api/chat",
    message: "/api/chat/message",
    markAllAsRead: "/api/chat/mark-all-as-read",
  },
  team: {
    index: "/api/team",
    invite: "/api/team/invite",
    user: "/api/team/user",
  },
  payment: "/api/payment",
  activityLog: "/api/activity_log",
  contact: "/api/contact",
  form: "/api/form",
  verifyPassword: "/api/auth/verify-password",
  referenceVerification: "/api/reference-verification",
  checkUserExists: "/api/auth/check-user-exists",
  checkUserType: "/api/auth/check-user-type",
  geminiFromText: "/api/gemini/from-text",
  invite: "/api/invite",
  felix: {
    jobFeedback: "/api/felix/job/feedback",
    jobDetails: "/api/felix/job/details",
    conversations: {
      index: "/api/felix/conversations",
    },
  },
};
